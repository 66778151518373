import axios from 'axios'

//获取应用包列表
export const GetPackageList = (params) => axios.get('/api/package/query', {
    params: params
})
//创建应用包
export const CreatePackage = (params) => axios.post('/api/package/insert', {
    ...params
})
//应用包删除
export const DelPackage = (params) => axios.get('/api/package/del', {
    params: params
})
//创建应用
export const CreateAppx = (params, packageId) => axios.post(`/api/appx/${packageId}/createAppx`, {
    ...params
})
//删除应用
export const DelApp = (params) => axios.delete('/api/appx/del_app/' + params)

//获取应用包下应用列表 
export const GetAppx = (params) => axios.post('/api/appx', {
    ...params
})
// 编辑表单保存
export const AddAppContent = (params) => axios.post('/api/appx/saveAppContent', {
    ...params
})
//编辑表单发布
export const IssueAppContent = (params) => axios.post('/api/appx/issueAppContent', {
    ...params
})
// 获取表单列表
export const GetFormList = (params) => axios.get('/api/appx', {
    params: params
})
// 获取表单表头
export const GetFormHeader = (params) => axios.get('/api/appx/query/headers', {
    params: params
})
// 获取表单表头2    mes特殊需求
export const GetFormHeader2 = (params) => axios.get('/api/appx/query/headers/custom', {
    params: params
})
// 获取表单信息
export const GetFormInfo = (params) => axios.post('/api/appx/query/form_list', {
    ...params
})
// 获取表单信息   mes 特殊需求
export const GetFormInfo2 = (params) => axios.post('/api/appx/query/form_list/custom', {
    ...params
})
// 发布表单提交
export const SubmitForm = (params) => axios.post('/api/appx/insert/form', {
    ...params
})
// 获取关联列表
export const GetRelation = (params) => axios.get('/api/appx/query/form_list', {
    params: params
})
// 获取关联字段
export const GetRelationField = (params) => axios.get('/api/appx/query/field_list', {
    params: params
})
// 获取常用应用列表
export const CommonApp = (params) => axios.get('/api/common_app', {
    params: params
})
// 常用应用添加    后面可能改成获取应用信息
export const AddCommon = (params) => axios.post('/api/common_app/add', {
    ...params
})
// 获取收藏应用列表
export const CollectApp = (params) => axios.get('/api/collect_app', {
    params: params
})
// 收藏应用添加    
export const AddCollect = (params) => axios.post('/api/collect_app/add', {
    ...params
})
// 取消收藏应用
export const DelCollect = (params) => axios.get('/api/collect_app/delete', {
    params: params
})
// 复制应用
export const CopyApp = (params) => axios.post('/api/appx/copy_app', {
    ...params
})
// 数据关联
export const DataLink = (params) => axios.post('/api/appx/link', {
    ...params
})
// 下拉框数据关联
export const DataLinkValue = (params) => axios.post('/api/appx/link_value', {
    ...params
})
// // 导出获取表头
// export const GetHeadersQuery = (params) => axios.get('/api/appx/query/headers', {
//     params: params
// })
// 导出表单 
export const ExportExcel = (params) => axios.post('/api/excel/export', {
    ...params
}, {
    responseType: 'blob'
})
// 导出附件
export const ExportZip = (params) => axios.post('/api/excel/export/zip', {
    ...params
}, {
    responseType: 'blob'
})
// 上传表格
export const UploadPost = "/api/excel/upload_file"

// 获取上传表格数据
export const ExcelGetsample = (params) => axios.post('/api/excel/get_sample', {
    ...params
})

// 导入数据
export const ExcelImport = (params) => axios.post('/api/excel/import ', {
    ...params
})

// 字段管理 
export const PostFieldManage = (params) => axios.post('/api/appx/field/manage  ', params)

// 表头字段搜索 
export const GetQueryScreen = (params) => axios.get('api/appx/query/screen_value', {
    params: params
})

// 删除表格数据
export const DeleteRecord = (params) => axios.post('api/appx/_form/delete/record  ', {
    ...params
})

// 表单编辑
export const UpdateForm = (params) => axios.post('api/appx/_form/data/update  ', {
    ...params
})

// 表单拖拽
export const FormMove = (params, packageId) => axios.post(`/api/appx/${packageId}/form/move`, {
    ...params
})

// 新建子表单 
export const CreateSubform = (params, appid, fieldId) => axios.post(`/api/appx/${appid}/${fieldId}/createSubform`, {
    ...params
})

// 删除子表单 
export const DelSubform = (params, tableName) => axios.delete(`/api/appx/del_subform/${tableName}`, {
    ...params
})

//修改分组信息 
export const EditGrouping = (params) => axios.put('api/appx/update  ', {
    ...params
})

//修改应用基本信息
export const PutPackageUpdate = (params) => axios.put('api/package/update  ', {
    ...params
})

// 查询应用发布列表
export const GetAppIssue = (params) => axios.get('/api/appIssue/select', {
    params: params
})

// 设置应用发布权限组
export const SaveAppIssue = (params) => axios.post('/api/appIssue/save', {
    ...params
})

// 修改应用发布权限组
export const UpdateAppIssue = (params) => axios.post('/api/appIssue/update', {
    ...params
})

// 删除发布权限组
export const DeleteAppIssue = (params) => axios.delete('/api/appIssue/delete', {
    params: params
})

// 停止 启用 发布权限组
export const updateStatusAppIssue = (params) => axios.post('/api/appIssue/updateStatus', {
    ...params
})

// 获取所有权限
export const SelectPermission = (params) => axios.get('/api/appIssue/selectPermission', {
    params: params
})

// 字段是否重复
export const IsRepeat = (params) => axios.post('/api/appx/_form/data/checkRepeat', {
    ...params
})
// 根据id获取用户名称
export const SelectUserById = (params) => axios.get('/api/user/selectUserById', {
    params: params
})
// 根据id获取应用信息
export const SelectPackageById = (params) => axios.get('/api/package/one', {
    params: params
})

// 根据表单id 获取表单详情
export const GetFormDetails = (params) => axios.post('/api/appx/query/form_one', {
    ...params
})
// 获取数据日志列表
export const GetLogList = (params) => axios.get('/api/comment/query/changelog_list', {
    params: params
})
// 获取评论列表
export const GetCommentList = (params) => axios.get('/api/comment/query/comment_list', {
    params: params
})
// 表单单挑记录详情评论
export const InsertComment = (params) => axios.post('/api/comment/insert/comment', {
    ...params
})
// 表单详细页编辑提交
export const SubmitForm2 = (params) => axios.post('/api/appx/_form/update', {
    ...params
})
//  获取关联数据列表
export const GetLinkList = (params) => axios.post('/api/appx/query/link_list', {
    ...params
})
//  获取打印机清单
export const GetPrinterList = (params) => axios.post('/api/appx/getPrinterList', {
    ...params
})
//  打印二维码
export const PrintBtnQr = (params) => axios.post('/api/appx/printBtnQr', {
    ...params
})
// 根据角色id 查询角色信息
export const GetRoleInfo = (params) => axios.get('/api/role/one', {
    params: params
})
// 根据部门id 查询部门信息
export const GetDeptInfo = (params) => axios.get('/api/dept/one', {
    params: params
})
// 根据部门id 查询部门信息
export const GetRemindlogList = (params) => axios.get('/api/remind/query/remindlog_list', {
    params: params
})
// 预警消息  已读
export const UpdateRemind = (params) => axios.post('/api/remind/update', {
    ...params
})
// 预警消息  已处理
export const HandleRemind = (params) => axios.post('/api/remind/updateIsHandle', {
    ...params
})
// 预警消息  已处理
export const chukuruku = (params) => axios.post('/api/appx/_form/move/record', {
    ...params
})


// 上传图片
export const UploadImagePost = "/api/file/uploadImage"

export const FilePathUrl = "/image/"