import React, { useEffect, useState, useReducer, useContext } from "react";
import Style from "./member.module.less";
import { Collapse, Input, Checkbox, Divider } from 'antd';
import { CloseOutlined, PlusCircleFilled } from '@ant-design/icons';
import Addmember from "../addmember/addmember"
import C from "../../../../pages/index/context"

const CheckboxGroup = Checkbox.Group;
const { Panel } = Collapse;

function Member(params) {
  const { hide } = useContext(C)


  const onChange2 = (key) => {
    // console.log(key);
  };

  // 复选框
  const plainOptions = ['用户1', '用户2', '用户3'];  //所有用户
  const defaultCheckedList = ['用户1'];             //所有用户
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  // 添加成员对话框
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    hide()
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={Style.Member}>
        <div className={Style.headbox}>
          <div className={Style.headtit}>成员</div>
        </div>
        <div className={Style.inpbox}>
          <Input placeholder="搜索成员" />
        </div>
        <div className={Style.conbox}>
          <Collapse onChange={onChange2}>
            <Panel header="全部成员" key="1">
              <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
            </Panel>
          </Collapse>
        </div>
        <div className={Style.bombox}>
          <div className={Style.bombox_l}>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              全部
            </Checkbox>
          </div>
          <div className={Style.bombox_r}>
            <div className={Style.addbox} onClick={showModal}><PlusCircleFilled style={{ color: "#aaa" }} />  邀请新成员</div>
          </div>
          <C.Provider value={{ isModalOpen, handleOk, handleCancel }}>
            <Addmember />
          </C.Provider>
        </div>
      </div>
    </>
  )

}

export default Member