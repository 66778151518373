import { lazy, Suspense } from "react";
import { KeepAlive } from 'react-activation'
// import { RouteObject } from "react-router-dom";
import "../App.less";
import { Spin } from "antd";
import Applayout from "../Applayout";
const Login = lazy(() => import("../pages/Logins/login/login"));
const Register = lazy(() => import("../pages/Logins/Register/register"));
const Reset = lazy(() => import("../pages/Logins/Reset/reset"));
const Index = lazy(() => import("../pages/index/index"));
const Organization = lazy(() => import("../pages/organization/organization"));
const Datahandle = lazy(() => import("../component/Index/datahandle/datahandle"));
const Datamatter = lazy(() => import("../component/Index/datamatter/datamatter"));
const LowCode = lazy(() => import("../pages/lowCode/lowCode"));
const Release = lazy(() => import("../component/lowCode/release/release"));
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
const Basicinfo = lazy(() => import("../component/administration/basicinfo/basicinfo"));
const Application = lazy(() => import("../component/administration/application/application"));
const Overview = lazy(() => import("../component/administration/overview/overview"));
const Jurisdiction = lazy(() => import("../component/administration/jurisdiction/jurisdiction"));
const Administration = lazy(() => import("../pages/administration/administration"));
const Userinfo = lazy(() => import("../component/PersonalCenter/userinfo/userinfo"));
const Recommend = lazy(() => import("../component/PersonalCenter/recommend/recommend"));
const PersonalCenter = lazy(() => import("../pages/personalCenter/personalCenter"));
const NewDashboard = lazy(() => import("../pages/newDashboard/newDashboard"));
const DataSet = lazy(() => import("../pages/dataSet/dataSet"));
const CreateDataSet = lazy(() => import("../pages/dataSet/createDataSet"));
const DataSource = lazy(() => import("../pages/dataSource/dataSource"));
const lazyLoad = (children, id) => {
  return (
    <Suspense
      fallback={
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" tip="Loading..." />
        </div>
      }
    >
      {
        id ?
          <KeepAlive id={id}>
            {children}
          </KeepAlive> :
          <>{children}</>
      }

    </Suspense>
  );
};
// 封装一层 专门负责显示页面标题
const DomTitle = ({ item }) => {
  document.title = item.title;
  return <item.component />
}


const router = [
  {
    path: "/",
    element: <Applayout />,
    children: [
      {
        index: true,
        element: lazyLoad(<Index />),
      },
      {
        path: "/administration",
        element: lazyLoad(<Administration />, 'administration'),
        children: [

          {
            index: true,
            element: lazyLoad(<Overview />, 'overview'),
          },
          {
            path: "/administration/basicinfo",
            element: lazyLoad(<Basicinfo />, 'basicinfo'),
          },
          {
            path: "/administration/application",
            element: lazyLoad(<Application />, 'application'),
          },
          {
            path: "/administration/jurisdiction",
            element: lazyLoad(<Jurisdiction />, 'jurisdiction'),
          },

        ],
      },
      {
        path: "/personalCenter",
        element: lazyLoad(<PersonalCenter />, 'personalCenter'),
        children: [

          {
            index: true,
            element: lazyLoad(<Userinfo />, 'userinfo'),
          },
          {
            path: "/personalCenter/recommend",
            element: lazyLoad(<Recommend />, 'recommend'),
          },
        ],
      },
      {
        path: "/dashboard",
        element: lazyLoad(<Dashboard />),
      },
      {
        path: "/dataSet",
        element: lazyLoad(<DataSet />),
      },
      {
        path: "/dataSource",
        element: lazyLoad(<DataSource />),
      },
    ],
  },
  // 登录
  {
    path: "/login",
    element: lazyLoad(<Login />),
  },
  // 注册
  {
    path: "/register",
    element: lazyLoad(<Register />),
  },
  // 忘记密码
  {
    path: "/reset",
    element: lazyLoad(<Reset />),
  },
  // 低代码表单
  {
    path: "/lowCode",
    element: lazyLoad(<LowCode />),
  },
  // 全部代办
  {
    path: "/datahandle",
    element: lazyLoad(<Datahandle />),
  },
  // 全部申请
  {
    path: "/datamatter",
    element: lazyLoad(<Datamatter />),
  },
  // 应用发布
  {
    path: "/release",
    element: lazyLoad(<Release />),
  },
  // 组织
  {
    path: "/organization",
    element: lazyLoad(<Organization />),
  },
  // 仪表盘
  {
    path: "/newDashboard",
    element: lazyLoad(<NewDashboard />),
  },
  // 数据集
  {
    path: "/createDataSet",
    element: lazyLoad(<CreateDataSet />),
  },
];

export default router;
