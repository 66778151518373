import React, { useEffect, useState, useReducer, useContext } from "react";
import Style from "./appPackage.module.less";
import { Checkbox, Tooltip, Modal, Input, Select, Button, Popover } from 'antd';
import { useNavigate } from "react-router-dom"
import { CloseOutlined, PlusCircleFilled } from '@ant-design/icons';
import Member from "../member/member"
import C from "../../../../pages/index/context"

const { Option } = Select;

function AppPackage(props) {
  const { isModalOpen, AppPackageHandleOk, AppPackageHandleCancel } = props
  const tit = <div style={{ fontSize: "20px", paddingTop: "10px" }}>应用</div>

  const [infodata, setinfodata] = useState({  //应用信息
    tit: "77",
    time: "4-18 09:26",
    id: "17f5e1cb"
  })

  const [boolnum, setboolnum] = useState(1)   //可见范围
  const handleChange = (value) => {
    setboolnum(value)
  };


  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };
  const content2 = (            //选择成员
    <div className={Style.newbox2}>
      <C.Provider value={{ hide }}>
        <Member />
      </C.Provider>
    </div>
  );

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <>
      <Modal title={tit} open={isModalOpen} onCancel={AppPackageHandleCancel} footer={null}>
        <div className={Style.AppPackage}>
          {/* 基本信息 */}
          <div className={Style.head}>
            <div className={Style.head_l}></div>
            <div className={Style.head_r}>
              <div className={Style.tit}>应用包</div>
              <div className={Style.name}>
                <div className={Style.tx}>
                  <div className={Style.tp}></div>
                  <div className={Style.nc}>{infodata.tit}</div>
                </div>
                <div className={Style.txt}>{infodata.time}创建</div>
                <div className={Style.txt}>应用ID: {infodata.id}</div>
              </div>
            </div>
          </div>
          {/* 可见范围 */}
          <div className={Style.range}>
            <div className={Style.tit}>可见范围</div>
            <div className={Style.content}>
              <Select defaultValue="工作区可见" style={{ width: "100%" }} onChange={handleChange}>
                <Option value="1">工作区可见</Option>
                <Option value="2">指定成员可见(默认管理员可见)</Option>
              </Select>
            </div>
          </div>
          {/* 添加指定成员 */}
          <div className={Style.add} style={{ display: boolnum == 1 ? "none" : "flex" }}>
            <Popover content={content2} trigger="click" placement="bottom" open={open} onOpenChange={handleOpenChange}>
              <div className={Style.addicon}>+</div>
            </Popover>
            <div className={Style.tp}></div>
            <div className={Style.tp}></div>
          </div>
          <div className={Style.btnbox}>
            <button className={Style.btn1} onClick={AppPackageHandleCancel}>取消</button>
            <button className={Style.btn2} onClick={AppPackageHandleOk}>确定</button>
          </div>
        </div>

      </Modal>
    </>
  )

}

export default AppPackage