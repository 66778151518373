/*
 * @Author: your name
 * @Date: 2022-01-06 14:15:48
 * @LastEditTime: 2022-01-06 14:16:55
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \houtai\src\pages\newDashboard\context.js
 */
import React from "react"

const Context = React.createContext();

export default Context