import React, { useEffect, useState, useReducer, useContext } from "react";
import Style from "./addmember.module.less";
import { Checkbox, Tooltip, Modal, Input, Select, Button, Popover } from 'antd';
import { useNavigate } from "react-router-dom"
import { CopyOutlined, PlusCircleFilled } from '@ant-design/icons';
import C from "../../../../pages/index/context"


const { Option } = Select;

function Addmember(params) {
  const tit = <div style={{ fontSize: "20px", paddingTop: "10px" }}>添加成员</div>
  const { isModalOpen, handleOk, handleCancel } = useContext(C)

  const [type, settype] = useState(1)
  return (
    <>
      <Modal title={tit} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width="600px">
        <div className={Style.Addmember}>
          <div className={Style.headbox}>
            <div className={type == 1 ? Style.headtits : Style.headtit} onClick={() => settype(1)}>
              <CopyOutlined /> 搜索邀请
            </div>
            <div className={type == 2 ? Style.headtits : Style.headtit} onClick={() => settype(2)}>
              <CopyOutlined /> 链接邀请
            </div>
            <div className={type == 3 ? Style.headtits : Style.headtit} onClick={() => settype(3)}>
              <CopyOutlined /> 二维码邀请
            </div>
          </div>
          <div className={Style.conbox}>
            <div className={Style.inpbox}>
              <Input placeholder="输入用户完整邮箱" style={{ width: "480px" }} />
              <button className={Style.btn}>搜索</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )

}

export default Addmember