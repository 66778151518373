import axios from 'axios'
import qs from 'qs'
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BASE_URL, TIMEOUT } from "./config"
import { message, Spin } from 'antd';

axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = BASE_URL;

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
    (config) => {
        if (config.url.includes('postek/print')) {
            // config.data = qs.stringify(config.data)
            config.headers = {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            };
        } else { 
            config.data = JSON.stringify(config.data);
            config.headers = {
                "Content-Type": "application/json"
            };
        }
        let token = localStorage.getItem("token");
        if (token) {
            config.headers['token'] = token
        }
        if (config.headers.isLoading !== false) {
            // showLoading()
        }
        return config;
    },
    (error) => {
        // if (error.config.headers.isLoading !== false) {
            // hideLoading()
        // }
        return Promise.reject(error);
    }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
    (response) => {
        if (response.config.headers.isLoading !== false) {
            // hideLoading()
        }
        if (response.data.code === 403) {
            message.warning('登录失效')
            localStorage.removeItem("token");
            // window.location.href = '/#/login'
        } if(response.data instanceof Blob){
            return response
        } else {
            return response.data;
        }
    },
    (error) => {
        // if (error.config.headers.isLoading !== false) {
            // hideLoading()
        // }
        // message.warning('请求出错')
    }
);

// 当前正在请求的数量
let requestCount = 0

// 显示loading
function showLoading() {
    if (requestCount === 0) {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.render(<Spin tip="加载中..." size="large" />, dom)
    }
    requestCount++
}

// 隐藏loading
function hideLoading() {
    requestCount--
    if (requestCount === 0) {
        document.body.removeChild(document.getElementById('loading'))
    }
}