import React, { useEffect, useState, useRef } from "react";
import Style from "./applyInfo.module.less";
import { Checkbox, Tooltip, Modal, Input, Select, Button, Popover, message } from "antd";
import { CloseOutlined, PlusCircleFilled, EditOutlined } from "@ant-design/icons";
import C from "../../../pages/index/context";
import Member from "../../administration/jurisdiction/member/member";
import AppPackage from "../../administration/jurisdiction/appPackage/appPackage";
import { PutPackageUpdate } from "../../../api";

const { Option } = Select;

function ApplyInfo(props) {
  const { isModalVisible, handleOk, handleCancel, info, getPackageList, setDataName } = props;
  const inputRef = useRef(null);
  const [inpBool, setinpBool] = useState(false)
  const [boolnum, setboolnum] = useState(1); //可见范围
  const [isModalOpen, setIsModalOpen] = useState(false); // 新建应用包窗口
  const [open, setOpen] = useState(false); //选择成员窗口判断
  const [value, setValue] = useState(info?.name)
  const tit = (
    <div style={{ fontSize: "20px", paddingTop: "10px" }}>{value}</div>
  );

  useEffect(() => {
    setValue(info?.name)
  }, [info?.id])

  useEffect(() => {
    if (inpBool) {
      inputRef.current.focus({
        cursor: 'all',
      });
    }
  }, [inpBool])

  const [infodata, setinfodata] = useState({
    //应用信息
    tit: "77",
    time: "4-18 09:26",
    id: "17f5e1cb",
  });

  const handleChange = (value) => {
    setboolnum(value);
  };

  const [listbox, setlistbox] = useState([
    //已选应用包
    { tit: "应用包1" },
    { tit: "应用包2" },
    { tit: "应用包3" },
    { tit: "应用包4" },
  ]);
  const dellistbox = (v) => {
    //删除已选应用包
    listbox.splice(v, 1);
    setlistbox([...listbox]);
  };

  const onChange = (checkedValues) => {
    //应用包复选框
  };
  const options = [
    {
      label: "未命名应用包1",
      value: "1",
    },
    {
      label: "未命名应用包2",
      value: "2",
    },
    {
      label: "未命名应用包3",
      value: "3",
    },
    {
      label: "未命名应用包4",
      value: "4",
    },
    {
      label: "未命名应用包5",
      value: "5",
    },
    {
      label: "未命名应用包6",
      value: "6",
    },
    {
      label: "未命名应用包7",
      value: "7",
    },
  ];



  const showModal = () => {
    setIsModalOpen(true);
    hide2();
  };

  const AppPackageHandleOk = () => {
    setIsModalOpen(false);
  };

  const AppPackageHandleCancel = () => {
    setIsModalOpen(false);
  };

  const [open2, setOpen2] = useState(false); //选择成员窗口判断

  const hide2 = () => {
    setOpen2(false);
  };
  const handleOpenChange2 = (newOpen) => {
    setOpen2(newOpen);
  };

  const content = //选择应用包
    (
      <div className={Style.newbox}>
        <div className={Style.addbox} onClick={showModal}>
          <PlusCircleFilled style={{ color: "#aaa" }} /> 新应用
        </div>
        <div className={Style.box}>
          <Checkbox.Group options={options} onChange={onChange} />
        </div>
      </div>
    );


  const hide = () => {
    setOpen(false);
  };
  const content2 = //选择成员
    (
      <div className={Style.newbox2}>
        <C.Provider value={{ hide }}>
          <Member />
        </C.Provider>
      </div>
    );

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };


  // 确定
  const putPackageUpdate = async (type) => {
    let res = await PutPackageUpdate({
      ...info,
      name: value
    });
    if (res.code === 1) {
      setDataName(value)
      getPackageList()
      message.success("修改成功");
    } else {
      message.warning(res.msg)
    }
  }

  return (
    <>
      <Modal
        title={tit}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div className={Style.Info}>
          {/* 基本信息 */}
          <div className={Style.head}>
            <div className={Style.head_l}>{value ? value[0] : ""}</div>
            <div className={Style.head_r}>
              <div className={Style.tit}>
                <div className={Style.titbox}
                  style={{ display: inpBool === false ? "" : "none" }}
                  onClick={() => {
                    setinpBool(true);
                  }}>
                  {value}   <EditOutlined className={Style.icon} />
                </div>
                <Input
                  defaultValue=''
                  value={value}
                  ref={inputRef}
                  onBlur={() => { setinpBool(false); }}
                  style={{ display: inpBool ? "" : "none" }}
                  onChange={(e) => {
                    setValue(e.target.value)
                  }}
                />
              </div>
              <div className={Style.name}>
                <div className={Style.tx}>
                  <div className={Style.tp}>{info?.creatorId[0]}</div>
                  <div className={Style.nc}>{info?.creatorId}</div>
                </div>
                <div className={Style.txt}>{info?.createTime}创建</div>
                <div className={Style.txt}>应用ID: {info?.id}</div>
              </div>
            </div>
          </div>
          {/* 可见范围 */}
          <div className={Style.range}>
            <div className={Style.tit}>可见范围</div>
            <div className={Style.content}>
              <Select
                defaultValue="工作区可见"
                style={{ width: "100%" }}
                onChange={handleChange}
              >
                <Option value="1">工作区可见</Option>
                {/* <Option value="2">指定成员可见(默认管理员可见)</Option> */}
              </Select>
            </div>
          </div>
          {/* 添加指定成员 */}
          <div
            className={Style.add}
            style={{ display: boolnum == 1 ? "none" : "flex" }}
          >
            <Popover
              content={content2}
              trigger="click"
              placement="bottom"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <div className={Style.addicon}>+</div>
            </Popover>
            <div className={Style.tp}></div>
            <div className={Style.tp}></div>
          </div>
          {/* 所属应用包 */}
          <div className={Style.belonging}>
            {/* <div className={Style.tit}>
              所属应用 <span>*</span>
            </div>
            <div className={Style.content}>
              <Popover
                content={content}
                trigger="click"
                placement="bottom"
                open={open2}
                onOpenChange={handleOpenChange2}
              >
                <button className={Style.types}>选择应用</button>
              </Popover>
              {listbox.map((item, index) => {
                return (
                  <button className={Style.type} key={index}>
                    {item.tit}
                    <div
                      className={Style.iconbox}
                      onClick={() => dellistbox(index)}
                    >
                      <CloseOutlined className={Style.icon} />
                    </div>
                  </button>
                );
              })}
            </div> */}
            <div className={Style.btnbox}>
              <Button onClick={handleCancel} style={{ marginRight: "15px" }}>
                取消
              </Button>
              <Button type="primary" onClick={() => { handleOk(); putPackageUpdate() }}>
                确定
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <AppPackage
        isModalOpen={isModalOpen}
        AppPackageHandleOk={AppPackageHandleOk}
        AppPackageHandleCancel={AppPackageHandleCancel}
      />
    </>
  );
}

export default ApplyInfo;
