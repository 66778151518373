import React, { useEffect, useState, useRef, useCallback } from "react";
import { useRoutes } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { createWebSocket, closeWebSocket } from './utils/socket.js'
import "./App.less";
// import "./current.css";

import router from "./router";
//  console.log(
//       '                   _oo0oo_                     \n' +
//       '                  o8888888o                    \n' +
//       '                  88" . "88                    \n' +
//       '                  (| -_- |)                    \n' +
//       '                   O\\ = /O                    \n' +
//       "               ____/`---'\\____                  \n" +
//       "             .   ' \\\\| |// `.                  \n" +
//       '              / \\\\||| : |||// \\               \n' +
//       '           / _||||| -卍- |||||- \\                \n' +
//       '              | | \\\\\\ - /// | |               \n' +
//       "            | \\_| ''\\---/'' | |                \n" +
//       '             \\ .-\\__ `-` ___/-. /              \n' +
//       "          ___`. .' /--.--\\ `. . __              \n" +
//       '       ."" "< `.___\\_<|>_/___. ` >" "".        \n' +
//       '      | | : `- \\`.;`\\ _ /`;.`/ - ` : | |       \n' +
//       '        \\ \\ `-. \\_ __\\ /__ _/ .-` / /          \n' +
//       "======`-.____`-.___\\_____/___.-`____.-'======  \n" +
//       "                   `=---='                     \n" +
//       '.............................................  \n\t\t' +
//       '佛祖镇楼                  BUG辟易                          \n\t' +
//       '佛曰:\n\t\t' +
//       '写字楼里写字间，写字间里程序员；\n\t\t' +
//       '程序人员写程序，又拿程序换酒钱。\n\t\t' +
//       '酒醒只在网上坐，酒醉还来网下眠；\n\t\t' +
//       '酒醉酒醒日复日，网上网下年复年。\n\t\t' +
//       '但愿老死电脑间，不愿鞠躬老板前；\n\t\t' +
//       '奔驰宝马贵者趣，公交自行程序员。\n\t\t' +
//       '别人笑我忒疯癫，我笑自己命太贱；\n\t\t' +
//       '不见满街漂亮妹，哪个归得程序员？'
//     )

ConfigProvider.config({
  theme: {
    primaryColor: '#2DDFA1',
  },
});

function App() {
  const userInfo = useRef({});


  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      userInfo.current = JSON.parse(localStorage.getItem("userInfo"))
      createWebSocket(userInfo.current.phone)
    } 
  }, [])

  return useRoutes(router);
}

export default App;
