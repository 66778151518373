import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './api/severs.js';
import reportWebVitals from './reportWebVitals';
import { HashRouter, BrowserRouter } from "react-router-dom";
import { AliveScope } from 'react-activation'
import './statics/iconfont/iconfont.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

ReactDOM.render(
  <HashRouter>
    <AliveScope>
      <App />
    </AliveScope>
  </HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
