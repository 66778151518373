// import react, { useRef, useEffect } from 'react'
import { UpdateRemind, HandleRemind } from '../api/index'
import { Modal } from 'antd';

let websocket, lockReconnect = false;
let createWebSocket = (phone) => {
  let url = `wss://tt.kuis.cn/webSocket/${phone}`
  // let url = `ws://192.168.1.115:8001/webSocket/${phone}`, // 公司测试
  // let url = `ws://192.168.1.104:5937webSocket/${phone}`,   //后台a本地地址
  websocket = new WebSocket(url);
  websocket.onopen = function () {
    heartCheck.reset().start();
    console.log("websocket已打开");
  }
  websocket.onerror = function () {
    reconnect(url);
  };
  websocket.onclose = function (e) {
    console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
  }
  websocket.onmessage = function (msg) {
    console.log("websocket已连接");
    console.log(JSON.parse(msg.data));  // 第一次进去会显示：连接成功
    lockReconnect = true;
    //event 为服务端传输的消息，在这里可以处理
    let data = JSON.parse(msg.data)
    if (data.remindTitle) {
      if (Modal) {
        Modal.destroyAll()
      }
      Modal.confirm({
        title: data.remindTitle,
        content: data.remindContent,
        cancelText: '已读',
        okText: '已处理',
        // confirmStyle: { height: '400px' },
        // bodyStyle: { height: '400px' },
        onCancel: () => {
          updateRemind(data)
          console.log('关闭了');
        },
        onOk: () => {
          handleRemind(data)
          console.log('已读了');
        }
      })
    }
  }
}
const updateRemind = async (data) => {
  let res = await UpdateRemind({
    id: data.id,
    // phone: data.phone,
    isRead: 1,
    // remindId: data.remindId,
    // tenantId: data.tenantId,
    // remindUniqueId: data.remindUniqueId,
    remindContent: data.remindContent,
    remindTitle: data.remindTitle,
    uniqueId: data.uniqueId

  })
  if (res.code === 1) {
  }
}
const handleRemind = async (data) => {
  let res = await HandleRemind({
    id: data.id,
    // phone: data.phone,
    isRead: 1,
    isHandle: 1,
    // remindId: data.remindId,
    // tenantId: data.tenantId,
    // remindUniqueId: data.remindUniqueId,
    remindContent: data.remindContent,
    remindTitle: data.remindTitle,
    uniqueId: data.uniqueId

  })
  if (res.code === 1) {
  }
}
let reconnect = (url) => {
  if (lockReconnect) return;
  //没连接上会一直重连，设置延迟避免请求过多
  setTimeout(function () {
    createWebSocket(url);
    lockReconnect = false;
  }, 4000);
}
let heartCheck = {
  timeout: 60000, //60秒
  timeoutObj: null,
  reset: function () {
    clearInterval(this.timeoutObj);
    return this;
  },
  start: function () {
    this.timeoutObj = setInterval(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      //onmessage拿到返回的心跳就说明连接正常
      // websocket.send("HeartBeat");
    }, this.timeout)
  }
}
//关闭连接
let closeWebSocket = () => {
  websocket && websocket.close();
}

export {
  websocket,
  createWebSocket,
  closeWebSocket
};